export class DynamicPricing {

  /*
   *  CTOR
   *  Takes CSS classes that wil be applied to DOM elements if the price is adjusted
   *
   *
   */
  constructor({priceClasses, suffixClasses, origPriceClasses, origSuffixClasses, percentClasses, valueClasses}) {

    const COUPON_PRICES_URL = '/get_sku_coupon_prices';
    const GET_COUPON_URL = '/get_coupon';
    const SET_COUPON_URL = '/set_coupon';

    this.priceClasses = priceClasses;
    this.suffixClasses = suffixClasses;
    this.origPriceClasses = origPriceClasses;
    this.origSuffixClasses = origSuffixClasses;
    this.percentClasses = percentClasses;
    this.valueClasses = valueClasses;

    // Read the DOM and add any element with a data-qkn-sku data element to the arry of elements
    // These are DOM elements we will want to change base on the data coming back from the pricing API 
    // or the saaved pricing API data from local storage
    this.elems = document.querySelectorAll('[data-qkn-sku]');
    this.pricesToChange = [];
    this.yearly = false;

    // Create the prices to change array from the elements we already got, but add in the type of element 
    // we will be changing these type include :
    // data-qkn-type="percent"
    // data-qkn-type="originalPrice"
    // data-qkn-type="price_cents" etc. a full list can be found in the formatValue() function below
    this.elems.forEach((elem, index) => {

      let type = '';

      type = (elem.dataset.qknType);
      this.pricesToChange.push({sku: elem.dataset.qknSku, type: type, elem: elem});
    });
  }

  /*
   *  Main method called to populate the price JSON and update DOM
   */
  async adjustPrices() {
    let data = await this.getPrices();

    this.pricesToChange.forEach((priceElem, index) => {
      data.prices.forEach((price, index) => {
        if (priceElem.sku == price.sku) {
          let formattedValue = this.formatValue(price, priceElem.elem);
          if (formattedValue) {
            let beforeEvt = new CustomEvent('beforePriceUpdate', {
              detail: {
                priceInfo: price,
                type: priceElem.type
              }
            });
            let afterEvt = new CustomEvent('afterPriceUpdate', {
              detail: {
                priceInfo: price,
                type: priceElem.type
              }
            });
            priceElem.elem.dispatchEvent(beforeEvt);

            if (this.coupon) {
              priceElem.elem.textContent = formattedValue;
              priceElem.elem.className = this.getClassName(price, priceElem.elem);
            }
            else {
              if ((priceElem.type === 'price') || (priceElem.type === 'price_dollars') || (priceElem.type === 'price_cents')) {
                priceElem.elem.textContent = formattedValue;
                priceElem.elem.className = this.getClassName(price, priceElem.elem);
              }
              if ((priceElem.type === 'suffix') || (priceElem.type === 'full_suffix')) {
                priceElem.elem.textContent = formattedValue;
                priceElem.elem.className = this.getClassName(price, priceElem.elem);
              }
            }
            priceElem.elem.dispatchEvent(afterEvt);
          }
        }
      });
    });
  }

  /*
   *  Retrieve the JSON pricing data fromthe backend, passing any appropriate coupon
   */
  async getPrices() {

    let result = JSON.parse(localStorage.getItem('qknPriceData'));
    let one_hour_ago = Date.now() - (1000 * 60 * 60);
    let data_time = Math.floor(result ? result.time : 0);
    let yearly_set = result ? result.yearly : 0;


    if (result && (data_time > one_hour_ago) && (this.yearly === yearly_set)) {
      return result;
    }
    else {

      if (!this.url) {
        console.log('QKNPricing - no URL provided');
        return {};
      }

      let cURL = this.url;

      if (this.coupon) {
        if (cURL.indexOf('?') !== -1) {
          cURL = cURL + '&coupon=' + this.coupon;
        }
        else {
          cURL = cURL + '?coupon=' + this.coupon;
        }
      }
      if (this.yearly) {
        if (cURL.indexOf('?') !== -1) {
          cURL = cURL + '&yearly=1';
        }
        else {
          cURL = cURL + '?yearly=1';
        }
      }
      try {
        let response = await fetch(cURL);
        let data = await response.json();
        let prices = {time: Date.now(), yearly: this.yearly, prices: data};
        localStorage.setItem('qknPriceData', JSON.stringify(prices));
        return prices;
      }
      catch (e) {
        console.error('QKNPricing called ' + cURL);
        console.error('error : ' + e.message);
      }
      return;
    }
  }

  clearData() {
    localStorage.removeItem('qknPriceData');
  }

  setCoupon(coupon) {
    this.coupon = coupon;
  }

  setURL(url) {
    this.url = url;
  }

  setDomain(domain) {
    this.domain = domain;
  }

  setYearly(yearly) {
    this.yearly = yearly;
  }

  formatValue(price, elem) {
    let value = null;

    if (elem.dataset.qknType === 'suffix') {
      value = price.price_suffix ? price.price_suffix : '';
    }
    if (elem.dataset.qknType === 'full_suffix') {
      value = price.full_price_suffix ? price.full_price_suffix : '';
    }
    if (elem.dataset.qknType === 'price') {
      if (price.coupon_price !== '' && this.coupon) {
        value = price.currency_symbol + price.coupon_price;
      }
      else {
        value = price.currency_symbol + price.price;
      }
    }
    if (elem.dataset.qknType === 'price_dollars') {
      if (price.coupon_price !== '' && this.coupon) {
        value = price.currency_symbol + price.coupon_price.split('.')[0];
      }
      else {
        value = price.currency_symbol + price.price.split('.')[0];
      }
    }
    if (elem.dataset.qknType === 'price_cents') {
      if (price.coupon_price !== '' && this.coupon) {
        value = price.coupon_price.split('.')[1];
      }
      else {
        value = price.price.split('.')[1];
      }
      if (price.coupon_price == '' && this.coupon && price.free_trial) {
        value = '00';
      }
    }
    if (elem.dataset.qknType === 'originalPrice') {
      if (price.coupon_price !== '') {
        value = price.currency_symbol + price.price;
      }
    }
    if (((elem.dataset.qknType === 'percent') || (elem.dataset.qknType === 'value')) && (price.discount !== '')) {
      if (price.coupon_type === 'by_fixed') {
        value = parseFloat(price.discount).toFixed(2);

        if (value.slice(-3) === '.00') {
          value = value.slice(0, -3);
        }
        value = price.currency_symbol + value + ' off';
      }
      else {
        let discount = price.discount;
        discount = discount * 100;
        discount = Math.floor(discount / 50) * 50;
        discount = discount / 100;

        let decimal_value = parseFloat(discount).toFixed(2);
        let values = decimal_value.split('.');
      
        value = values[0] + '% off';
      }
    }
    // Free trials
    if (elem.dataset.qknType === 'billingCycle') {
      if (price.free_trial) {
        value = ' for ' + price.trial_period;
      }
    } 
    if (elem.dataset.qknType === 'billed') {
      if (price.free_trial) {
        value = price.renewal_price + ' /month after free trial. Billed annually.';
      }
    }    
    if (elem.dataset.qknType === 'inline-billed') {
      if (price.free_trial) {
        value = '& then ' + price.renewal_price + ' / month';
      }
    }    
    if (((elem.dataset.qknType === 'percent') || (elem.dataset.qknType === 'value')) && (price.free_trial)) {
      value = 'Free trial';
    }    
    
    return value;
  }


  /*
   *  Given a price object and a DOM element, return the classes that should be applied to that element
   */
  getClassName(price, elem) {
    let classes = elem.className;

    if (elem.dataset.qknType === 'price') {
      classes = classes.length > 0 ? classes + ' ' + this.priceClasses : this.priceClasses;
    }
    if ((elem.dataset.qknType === 'suffix') || (elem.dataset.qknType === 'full_suffix')) {
      classes = classes.length > 0 ? classes + ' ' + this.suffixClasses : this.suffixClasses;
    }
    if (elem.dataset.qknType === 'originalPrice') {
      if (price.coupon_price !== '') {
        if (elem.dataset.qknSuffix === 'true') {
          classes = classes.length > 0 ? classes + ' ' + this.origSuffixClasses : this.origSuffixClasses;
        }
        else {
          classes = classes.length > 0 ? classes + ' ' + this.origPriceClasses : this.origPriceClasses;
        }
      }
    }
    if (elem.dataset.qknType === 'percent') {
      if (price.coupon_price !== '' || price.free_trial) {
        classes = classes.length > 0 ? classes + ' ' + this.percentClasses : this.percentClasses;
      }
      if (price.free_trial) {
        classes = classes.length > 0 ? classes + ' ' + this.percentClasses + ' no-after ' : this.percentClasses + ' no-after ';
      }
    }
    if (elem.dataset.qknType === 'value') {
      if (price.coupon_price !== '' | price.free_trial) {
        classes = classes.length > 0 ? classes + ' ' + this.valueClasses : this.valueClasses;
      }
      if (price.free_trial) {
        classes = classes.length > 0 ? classes + ' ' + this.percentClasses + ' no-after ' : this.percentClasses + ' no-after ';
      }
    }
    return classes;
  }

  getCookieByName(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      return match[2];
    }
  }
}

